import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Herocorner from "../sections/corner/cornerhero.js";
import Testimonial from "../sections/index/indexreviews.js";
import Rte from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import Contentcorner from "../sections/corner/cornercontent.js";
import RecoveryCorner from "../sections/corner/cornerrecovery.js";
import CornerFaq from "../sections/corner/cornerfaq";
import Head from "../components/head"
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/corner.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import Pro from '../sections/nose/nosesteps.js';
import PopupForm from "../components/PopupForm/PopupForm"

const Corner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
    <Head title="Corner Of The Mouth Treatment UK" image="/images/dermamina-home.jpg"  description="Experts in Corner Of The Mouth treatments London" keywords="corner of mouth, corner of mouth London, corner of mouth treatment"/>
<PageWrapper headerDark>
  <PopupForm
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  />
<HeroComponent
    itext='<span style="color: #1a1a1a;">Corner of </span> <span style="color: #00aec7;">Mouth</span>'
    secondText="Boost your smile"
    videoAlt="Corner of mouth"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="1 treatment"
  bestalt="best Corner of mouth"
  resultstext="12-18 months"
  resultsalt="Corner of mouth results"
  costdata="From £300"
  costalt="Corner of mouth Cost"
  timedata="10 Minutes"
  timealt="Corner of mouth Duration Time"
  workdata="Immediately"
  workalt="Corner of mouth downtime work"
  paindata="None / Mild"
  painalt="Corner of mouth Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
<Testimonial />
<Contentcorner
  setIsModalOpen={setIsModalOpen}
/>
<CornerFaq
  setIsModalOpen={setIsModalOpen}
/>
<Clinic /> 
<Saveface />
<Rte />
</PageWrapper>

    </>
  );
};
export default Corner;
